import type DataLoaderType from 'dataloader';
import type { Appointment } from '../appointments';
import type { Projection } from '../common';
import type { IsomorphicTimestamp } from '../firebase';
import type { User } from '../users';
import type { StartsWith } from '../util';
import type { WithVetspireData } from '../vetspire';
import {
    PUBSUB_TOPIC_CUSTOMERIO_CLEANUP,
    PUBSUB_TOPIC_CUSTOMERIO_SEND,
    MERGE_CONVERTED_LEADS,
    REMOVE_INVALID_SUBSCRIBERS,
    MESSAGE_CONTENT_COLLECTION_NAME,
    ADD_CLIENT,
    DELETE_CLIENT,
    TRACK_EVENT,
    TRACK_ANONYMOUS_EVENT,
    MERGE_LEAD,
    MERGE_PEOPLE,
    EXPORT_VETSPIRE_CLIENT_DATA,
    EXPORT_APPOINTMENTS,
    EXPORT_CLIENTS,
    EXPORT_CLIENTS_ACTIVE,
    EXPORT_PROMO_CODES,
    EXPORT_PROMO_CODES_LOGS,
    EXPORT_LEADS,
    EXPORT_LOCATIONS,
    EXPORT_MOST_RECENT_EXAMS,
    EXPORT_NPS_SCORES,
    EXPORT_LEAD_PETS,
    EXPORT_PETS,
    SYNC_USER_ALIASES,
    EXPORT_PROMO_CODE_STATS,
    EXPORT_PATIENT_PLANS,
    EXPORT_SUBSCRIPTION_STATUS,
    EXPORT_VETSPIRE_PERSONAL_CLIENT_DATA,
    EXPORT_VETSPIRE_CLIENT_ADDRESSES,
    EXPORT_PROMO_CODE_REFERRAL_LOGS,
    EXPORT_PROMO_CODE_REFERRAL_STATS,
    EXPORT_DUPLICATE_PHONE_NUMBERS,
} from './constants';

export {
    PUBSUB_TOPIC_CUSTOMERIO_CLEANUP,
    PUBSUB_TOPIC_CUSTOMERIO_SEND,
    MERGE_CONVERTED_LEADS,
    REMOVE_INVALID_SUBSCRIBERS,
    MESSAGE_CONTENT_COLLECTION_NAME,
    ADD_CLIENT,
    DELETE_CLIENT,
    TRACK_EVENT,
    TRACK_ANONYMOUS_EVENT,
    MERGE_LEAD,
    MERGE_PEOPLE,
};

export interface PhoneNumberData {
    /** phoneNumber */
    phoneNumber?: string | null;
    /** structured `phoneNumber`, e.g. +17012345678 */
    intlPhoneNumber?: string | null;
    /** copy of `phoneNumber` at the time when `intlPhoneNumber` has been generated */
    intlPhoneNumberOrigin?: string | null;
}

export type CommunicationSettingsInput = {
    followUpMessageHour: number | null;
};

export type CommunicationSettings = {
    _id: string;
} & CommunicationSettingsInput;

export enum MessageType {
    followUp = 'followUp',
    // warning: the surgeryForm MessageType cannot be used
    // with an appointment as payload, because we use it
    // to send post-surgery PDFs to clients, and we cannot
    // guarantee to have an appointment linked to the Vetspire
    // encounter.
    surgeryForm = 'surgeryForm',
    infoPdf = 'infoPdf',
}

export const MESSAGE_TYPE = Object.freeze(MessageType);

export interface MessageContentInput {
    subject: string;
    content: string;
}

export interface MessageContent extends MessageContentInput {
    _id: MessageType;
}

export interface ExampleEventLocation {
    name: string;
    address: string | null;
    googleReviewLink?: string | null;
    yelpLink?: string | null;
    webLink: string | null;
    intlPhoneNumber: string | null;
    phoneNumber: string | null;
    email: string | null;
    timezone: string;
}

export interface ExampleEventData {
    firstName: string | null;
    pet: string;
    locationName: string;
    locationAddress: string;
    location: ExampleEventLocation;
    email: string;
    providerName: string | null;
}

export type ExampleEncounterEventData = ExampleEventData;

export interface ExampleAppointmentEventData extends ExampleEventData {
    time: string;
    date: string;
    timezone: string;
    timestamp: number;
    rescheduleLink: string;
    cancelLink: string;
    appointmentId: string;
    schedule?: string | null;
    appointmentType?: string | null;
    appointmentCategory?: string | null;
}

export interface AddClientMessage {
    type: typeof ADD_CLIENT;
    payload: {
        clientId: string;
        timestamp?: number;
        data: Record<string, unknown>;
    };
}

export interface DeleteClientMessage {
    type: typeof DELETE_CLIENT;
    payload: {
        clientId: string;
    };
}

export interface MergeLeadMessage {
    type: typeof MERGE_LEAD;
    payload: {
        leadId: string;
        clientId: string;
    };
}

export interface MergePeopleMessage {
    type: typeof MERGE_PEOPLE;
    payload: {
        primaryId: string;
        secondaryId: string;
    };
}

export interface TrackEventMessage {
    type: typeof TRACK_EVENT;
    payload: {
        clientId: string;
        event: string;
        timestamp?: number;
        data: Record<string, unknown>;
        attributes?: Record<string, unknown>;
    };
}

export interface TrackAnonymousEventMessage {
    type: typeof TRACK_ANONYMOUS_EVENT;
    payload: {
        event: string;
        timestamp?: number;
        data: Record<string, unknown>;
    };
}

export type CustomerIoMessage =
    | AddClientMessage
    | DeleteClientMessage
    | MergeLeadMessage
    | MergePeopleMessage
    | TrackEventMessage
    | TrackAnonymousEventMessage;

export interface MergeConvertedLeadsMessage {
    type: typeof MERGE_CONVERTED_LEADS;
}

export interface RemoveInvalidSubscribers {
    type: typeof REMOVE_INVALID_SUBSCRIBERS;
}

export type CleanSubscribersMessage =
    | MergeConvertedLeadsMessage
    | RemoveInvalidSubscribers;

const APPOINTMENT_EVENT_FIELDS = [
    '_id',
    'date',
    'timezone',
    'firstName',
    'lastName',
    'clientId',
    'email',
    'pet',
    'locationId',
    'reason',
    'sequence',
    'status',
    'isTelehealth',
    'vetspireScheduleName',
    'vetspireProviderName',
    'appointmentTypeName',
    'releaseFormSignatureId',
    'departmentId',
] as const;

export const APPOINTMENT_EVENT_PROJECTION: Projection =
    APPOINTMENT_EVENT_FIELDS.reduce(
        (projection, field) => ({
            ...projection,
            [field]: 1,
        }),
        {},
    );
export type AppointmentEventFields = (typeof APPOINTMENT_EVENT_FIELDS)[number];
export type AppointmentEventData = Pick<Appointment, AppointmentEventFields>;

const CLIENT_PROJECTION_FIELDS = [
    '_id',
    'email',
    'createdAt',
    'firstName',
    'lastName',
    'phoneNumber',
    'intlPhoneNumber',
    'active',
    'defaultLocation',
    'zipCode',
    'country',
    'address',
    'addressExtra',
    'city',
    'state',
] as const;
const CLIENT_PROJECTION_EXTRA_FIELDS = ['_vetspire.id', 'tags'] as const;

export const CLIENT_PROJECTION: Projection = [
    ...CLIENT_PROJECTION_FIELDS,
    ...CLIENT_PROJECTION_EXTRA_FIELDS,
].reduce((projection, field) => ({ ...projection, [field]: 1 }), {
    '_vetspire.id': 1,
});

export type ClientProjectionFields = (typeof CLIENT_PROJECTION_FIELDS)[number];
export type ClientData = Pick<User, ClientProjectionFields> & WithVetspireData;

const ADDRESS_PROPERTIES = [
    'zipCode',
    'country',
    'address',
    'city',
    'state',
] as const;

export const EXISTING_CLIENT_PROJECTION_FIELDS = [
    ...CLIENT_PROJECTION_FIELDS,
    ...ADDRESS_PROPERTIES,
];

export const EXISTING_CLIENT_PROJECTION: Projection =
    ADDRESS_PROPERTIES.reduce<Projection>(
        (projection, field) => ({
            ...projection,
            [field]: 1,
        }),
        {
            ...CLIENT_PROJECTION,
            addressExtra: 1,
        },
    );

export type ExistingClientProjectionFields =
    (typeof EXISTING_CLIENT_PROJECTION_FIELDS)[number];
export type ExistingClientData = Pick<User, ExistingClientProjectionFields> &
    WithVetspireData;

/**
 * augmentation
 */
export interface AugmentationPayload {
    offset: number;
    maxBatchSize?: number;
    importRun?: number;
    fullExport?: boolean;
}

export interface ExportAppointmentsMessage {
    type: typeof EXPORT_APPOINTMENTS;
    payload: AugmentationPayload;
}

export interface ExportClientsMessage {
    type: typeof EXPORT_CLIENTS;
    payload: AugmentationPayload;
}

export interface ExportClientsActiveMessage {
    type: typeof EXPORT_CLIENTS_ACTIVE;
    payload: AugmentationPayload;
}

export interface ExportPromoCodesMessage {
    type: typeof EXPORT_PROMO_CODES;
    payload: AugmentationPayload;
}

export interface ExportPromoCodesLogsMessage {
    type: typeof EXPORT_PROMO_CODES_LOGS;
    payload: AugmentationPayload;
}

export interface ExportPromoCodeReferralLogsMessage {
    type: typeof EXPORT_PROMO_CODE_REFERRAL_LOGS;
    payload: AugmentationPayload;
}

export interface ExportPromoCodeReferralStatsMessage {
    type: typeof EXPORT_PROMO_CODE_REFERRAL_STATS;
}

export interface ExportPromoCodeStatsMessage {
    type: typeof EXPORT_PROMO_CODE_STATS;
}

export interface ExportLeadsMessage {
    type: typeof EXPORT_LEADS;
    payload: AugmentationPayload;
}

export interface ExportLeadPetsMessage {
    type: typeof EXPORT_LEAD_PETS;
    payload: AugmentationPayload;
}

export interface ExportDuplicatePhoneNumbersMessage {
    type: typeof EXPORT_DUPLICATE_PHONE_NUMBERS;
    payload: AugmentationPayload;
}

export interface ExportLocationsMessage {
    type: typeof EXPORT_LOCATIONS;
    payload: AugmentationPayload;
}

export interface ExportMostRecentExamsMessage {
    type: typeof EXPORT_MOST_RECENT_EXAMS;
}

export interface ExportNpsScoresMessage {
    type: typeof EXPORT_NPS_SCORES;
    payload: AugmentationPayload;
}

export interface ExportPatientPlansMessage {
    type: typeof EXPORT_PATIENT_PLANS;
}
export interface ExportPetsMessage {
    type: typeof EXPORT_PETS;
    payload: AugmentationPayload;
}

export interface ExportSubscriptionStatusMessage {
    type: typeof EXPORT_SUBSCRIPTION_STATUS;
    payload: AugmentationPayload;
}

export interface ExportVetspireClientDataMessage {
    type: typeof EXPORT_VETSPIRE_CLIENT_DATA;
    payload: AugmentationPayload;
}

export interface ExportVetspirePersonalClientDataMessage {
    type: typeof EXPORT_VETSPIRE_PERSONAL_CLIENT_DATA;
    payload: AugmentationPayload;
}

export interface ExportVetspireClientAddressesMessage {
    type: typeof EXPORT_VETSPIRE_CLIENT_ADDRESSES;
    payload: AugmentationPayload;
}

export interface SyncUserAliasesMessage {
    type: typeof SYNC_USER_ALIASES;
    payload: Omit<AugmentationPayload, 'importRun'>;
}

export type AugmentationMessage =
    | ExportAppointmentsMessage
    | ExportClientsMessage
    | ExportClientsActiveMessage
    | ExportPromoCodesMessage
    | ExportPromoCodesLogsMessage
    | ExportPromoCodeReferralLogsMessage
    | ExportPromoCodeReferralStatsMessage
    | ExportPromoCodeStatsMessage
    | ExportLeadsMessage
    | ExportLeadPetsMessage
    | ExportDuplicatePhoneNumbersMessage
    | ExportLocationsMessage
    | ExportMostRecentExamsMessage
    | ExportNpsScoresMessage
    | ExportPatientPlansMessage
    | ExportPetsMessage
    | ExportSubscriptionStatusMessage
    | ExportVetspireClientDataMessage
    | ExportVetspirePersonalClientDataMessage
    | ExportVetspireClientAddressesMessage
    | SyncUserAliasesMessage;

export type AugmentationMessageType = AugmentationMessage['type'];
export type AugmentationMessageData = Omit<AugmentationMessage, 'type'>;

/**
 * migration from Customer.io to Braze
 */
export enum BrazeTriggerType {
    customEvent = 'customEvent',
    apiTriggeredCampaign = 'apiTriggeredCampaign',
    apiTriggeredCanvas = 'apiTriggeredCanvas',
}

export interface BrazeTriggerConfig {
    type: BrazeTriggerType;
    campaignId?: string;
    canvasId?: string;
}

export type BrazeCampaignTriggerConfig = Omit<
    Required<BrazeTriggerConfig>,
    'canvasId'
>;

export type BrazeCanvasTriggerConfig = Omit<
    Required<BrazeTriggerConfig>,
    'campaignId'
>;

/**
 * describes whether a trigger should be sent to Braze
 * or customer.io. Stored in RTDB at `communication/triggers`,
 * with the trigger's name as the key
 */
export interface TriggerSetting<Timestamp = number | Date> {
    sendToBraze: boolean;
    sendToCustomerIo: boolean;
    firstSeen: Timestamp;
    lastSeen: Timestamp;
    count: number;
    brazeConfig?: BrazeTriggerConfig;
    createdBy?: string;
}

export type TriggerSettingInput = Omit<
    TriggerSetting,
    'firstSeen' | 'lastSeen' | 'count' | 'createdBy'
>;

export const LEAD_IMPORT_PET_BOOLEAN_COLUMN_NAMES = [
    'microchipped',
    'spay_neuter',
    'petDeceased',
] as const;

export const LEAD_IMPORT_PET_COLUMN_TARGETS = [
    'petName',
    'species',
    'petAge',
    'petBirthday',
    'petWeight',
    'petBreed',
    'lastDental',
    'petSex',
    ...LEAD_IMPORT_PET_BOOLEAN_COLUMN_NAMES,
] as const;

export type LeadImportPetColumnTarget =
    (typeof LEAD_IMPORT_PET_COLUMN_TARGETS)[number];

export const LEAD_IMPORT_SUBSCRIPTION_PREFERENCES_TARGETS = [
    'email_subscribe',
    'sms_marketing',
    'sms_transactional',
    'sms_conversational',
] as const;

export const LEAD_IMPORT_SUBSCRIPTION_STATUS_TARGETS = [
    'email_subscribe',
] as const;

export type LeadImportSubscriptionStatusTarget =
    (typeof LEAD_IMPORT_SUBSCRIPTION_STATUS_TARGETS)[number];

export const LEAD_IMPORT_CUSTOM_ATTRIBUTE_TARGETS = [
    'firstName',
    'lastName',
    'dob',
    'city',
    'gender',
    'country',
    'phone',
    'address',
    'addressExtra',
    'preferredLocation',
    'nearestClinic',
    'region',
    'source',
    'state',
    'zipCode',
] as const;

export const STATIC_LEAD_IMPORT_COLUMN_TARGETS = [
    ...LEAD_IMPORT_CUSTOM_ATTRIBUTE_TARGETS,
    ...LEAD_IMPORT_SUBSCRIPTION_PREFERENCES_TARGETS,
    'email',
    'list',
] as const;

export type StaticLeadImportcolumnTarget =
    (typeof STATIC_LEAD_IMPORT_COLUMN_TARGETS)[number];

export type LeadImportColumnTarget =
    | StaticLeadImportcolumnTarget
    | LeadImportPetColumnTarget
    | StartsWith<'attributes.'>
    | 'unknown';

export const LEAD_IMPORT_COLUMN_TYPES = [
    'string',
    'boolean',
    'number',
    'date',
    'subscription_status',
] as const;

export type LeadImportColumnType = (typeof LEAD_IMPORT_COLUMN_TYPES)[number];

export interface LeadImportColumnConfig {
    ignore: boolean;
    type: LeadImportColumnType;
    target: LeadImportColumnTarget;
}

export enum LeadImportFileType {
    csv = 'csv',
    json = 'json',
    jsonl = 'jsonl',
}

export interface LeadImportColumn extends LeadImportColumnConfig {
    name: string;
}

export interface LeadImportInput {
    fileType: LeadImportFileType;
    columns: readonly LeadImportColumn[];
    name: string;
    list?: string;
}

export interface ImportError {
    rowNumber: number;
    target: LeadImportColumnTarget;
    error: string;
}

export interface InvalidImportRow {
    rowNumber: number;
    data: Record<string, unknown>;
}

export interface InvalidLeadImportRow extends InvalidImportRow {
    importId: string;
}

export interface LeadImportError extends ImportError {
    importId: string;
}

export interface LeadImportConfig<DateType = Date> extends LeadImportInput {
    createdAt: DateType;
    startedAt?: DateType;
    started: boolean;
    finished: boolean;
    finishedAt?: DateType;
    createdBy: string;
    total?: number;
    imported: number;
    numberOfErrors: number;
}

export type FirestoreLeadImportConfig = LeadImportConfig<IsomorphicTimestamp>;

/**
 * Braze Payload Tracking and storing
 */

export type BaseBrazePayloadData = {
    timestamp: Date;
    clientId?: string;
    event: string;
    data: Record<string, unknown>;
};

export type BrazePayloadData = BaseBrazePayloadData & {
    _id: string;
};

export type GraphQLBrazePayloadData = BrazePayloadData & {
    client?: string;
};

export type BrazePayloadClientDataLoader = DataLoaderType<
    string,
    string | null
>;

export type BrazeEventCount = {
    event: string;
    count: number;
};

export type BrazePayloadDataWithEventsArguments = {
    event?: string;
    limit?: number;
    offset?: number;
};

export const LEAD_SMS_SUBSCRIPTION_STATUS_CHANGE_FIELDS = [
    'smsMarketingOptedInAt',
    'smsMarketingOptedOutAt',
    'smsTransactionalOptedInAt',
    'smsTransactionalOptedOutAt',
    'smsConversationalOptedInAt',
    'smsConversationalOptedOutAt',
] as const;

export const LEAD_SUBSCRIPTION_STATUS_CHANGES_FIELDS = [
    'emailSubscribeOptedInAt',
    'emailSubscribeOptedOutAt',
    ...LEAD_SMS_SUBSCRIPTION_STATUS_CHANGE_FIELDS,
] as const;

export type LeadSmsSubscriptionStatusChangeField =
    (typeof LEAD_SMS_SUBSCRIPTION_STATUS_CHANGE_FIELDS)[number];

export type LeadSmsSubscriptionStatusChange = Partial<
    Record<LeadSmsSubscriptionStatusChangeField, Date>
>;

export type LeadSubscriptionStatusChangeField =
    (typeof LEAD_SUBSCRIPTION_STATUS_CHANGES_FIELDS)[number];
